<template>
  <div class="publish-person-info app_content">
    <div class="warp_body">
      <div class="publishPersonInfoBody">
        <van-form @submit="onSubmit">
          <van-cell-group inset>

            <van-field v-model="form.name" required label="姓名" label-width="105px" placeholder="请输入姓名"
                       :rules="[{ validator: validatorCompanyMessage }]"/>
            <van-field name="radio" label="性别">
              <template #input>
                <van-radio-group v-model="form.gender" direction="horizontal">
                  <van-radio name="1">男</van-radio>
                  <van-radio name="2">女</van-radio>
                </van-radio-group>
              </template>
            </van-field>
            <van-field v-model="form.phone" required label="电话" label-width="105px" placeholder="请输入电话"
                       :rules="[{ validator: validatorCompanyMessage }]"/>

            <van-field
                v-model="form.areaText"
                required
                is-link
                readonly
                name="picker"
                label="区域"
                placeholder="点击选择区域"
                @click="getAreaList"
                :rules="[{ validator: validatorAreaMessage }]"
            />
            <van-popup v-model:show="showAreaPicker" position="bottom">
              <van-picker
                  :columns="areaList"
                  @confirm="onConfirmArea"
                  @cancel="showAreaPicker = false"
              />
            </van-popup>

            <van-field v-model="form.address" rows="1"
                       autosize
                       required
                       maxlength="100"
                       type="textarea" label="地址"
                       placeholder="请输入地址" :rules="[{ validator: validatorCompanyAddressMessage }]"/>


            <van-field
                v-model="form.workTypeText"
                is-link
                readonly
                required
                name="picker"
                label="工种"
                placeholder="点击选择工种"
                @click="getWorkTypeList"
                :rules="[{ validator: validatorWorkTypeMessage }]"
            />
            <van-popup v-model:show="showWorkTypePicker" position="bottom">
              <van-picker
                  :columns="workTypeList"
                  @confirm="onConfirmWorkType"
                  @cancel="showWorkTypePicker = false"
              />
            </van-popup>

            <van-field v-model="form.workingAge" required label="工龄" type="number"  maxlength="2" placeholder="请输入工龄"
                       :rules="[{ validator: validatorCompanyMessage }]"/>

            <van-field
                v-model="form.introduce"
                rows="3"
                autosize
                required
                border="true"
                label="自我介绍"
                type="textarea"
                show-word-limit
            />
            <van-field
                v-model="form.projectExperience"
                rows="3"
                autosize
                required
                border="true"
                label="项目经验"
                type="textarea"
                show-word-limit
            />
            <van-field
                v-model="form.skills"
                rows="3"
                autosize
                required
                border="true"
                label="职业技能"
                type="textarea"
                show-word-limit
            />

          </van-cell-group>

          <div style="margin: 16px;text-align: center">
            <van-button round type="primary" style="width: 120px" native-type="submit">
              发布名片
            </van-button>
          </div>


        </van-form>


      </div>
    </div>
  </div>
</template>

<script>
import {onMounted, ref, toRaw} from 'vue';
import {onBeforeMount, reactive, toRefs, nextTick, onActivated, onDeactivated} from 'vue'
import {Toast} from 'vant'
import {useRouter} from 'vue-router'
import req from "../request/request";
import API_ORDER from "../api/Order";
import API from "../api/User";
import router from "../router";
import ChooseTime from "@/components/common/chooseTime";

export default {
  name: 'PublishPersonInfo',
  setup() {

    onMounted(() => {
    });

    const data = reactive({
          showAreaPicker: false,
          showWorkTypePicker: false,
          areaList: [],
          workTypeList: [],
          form: {
            areaId: '',
            area: '',
            areaText: '',
            name: '',
            gender: '',
            phone: '',
            workTypeText: '',
            workTypeId: '',
            workType: '',
            workingAge: '',
            projectExperience: '',
            introduce: '',
            skills: '',
            address: '',

          }
        },
    )

    const methodsMap = {

      onConfirmWorkType: (item) => {
        data.showWorkTypePicker = false
        data.form.workTypeText = item.text
        data.form.workType = item.text
        data.form.workTypeId = item.id
      },

      getWorkTypeList: () => {
        data.showWorkTypePicker = true
        req.request(API.workTypeList, null, {method: 'GET'})
            .then((res) => {
              data.workTypeList = res.data.data
            })
      },
      validatorCompanyMessage: (val) => {
        if (!val) {
          return '请输入单位名称'
        }
      },
      validatorAreaMessage: (val) => {
        if (!val) {
          return '请选择所在区域'
        }
      },
      validatorJobTitleMessage: (val) => {
        if (!val) {
          return '请输入项目名称'
        }
      },
      validatorCompanyAddressMessage: (val) => {
        if (!val) {
          return '请输入用工单位详细地址'
        }
      },
      onConfirmArea: (item) => {
        data.showAreaPicker = false
        data.form.areaText = item.text
        data.form.area = item.value
        data.form.areaId = item.id
      },
      onSubmit: (form) => {
        console.log('submit', form)
        let params = data.form
        req.request(API_ORDER.createWorkerCard , params,
            {method: 'POST'}, {message: '发布名片中...'})
            .then((res) => {
              if (res.data.code == 200) {
                router.push('/')
                Toast.success("发布成功！")
              }
            })

      },

      getAreaList: () => {
        data.showAreaPicker = true
        req.request(API.areaList, null, {method: 'GET'})
            .then((res) => {
              data.areaList = res.data.data
            })
      },
      changeVerificationCode: (val) => {
        data.form.verificationCode = val
      },
      changePhone: (val) => {
        console.log("data.form.companyPhone:", val)
        data.form.companyPhone = val
      }
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less">
.publishJobBody {
}
</style>
